<template>
  <div class="m-about">
    <!-- <div class="my">
      <div class="title">联系我们</div>

      <div class="list">
        <div class="item">
          <img src="../assets/img2.0/telegram.png" alt="" />
          <div class="span">Telegram</div>
        </div>
      </div>
    </div> -->
    <div class="bottom">
      <div class="title">资助</div>
      <div class="logo">
        <img src="../assets/images/qrCode.png" alt="" />
        <!-- <div class="but" @click="trans()">点击资助</div> -->
      </div>
      <div class="p">
        地址：<span class="addr"> {{ store.zzAddr }}</span>
      </div>
      <div class="p">
        我们接受USDT、PSC、BNB、Matic
        捐赠感谢你的捐赠，你的支持是我们前进的动力(支持PSC、Polygon、BNB
        Chain网络)。
      </div>
    </div>
    <div class="fen"></div>
    <div class="office">
      <!-- <div class="title">PSC官方</div> -->
      <div class="title">联系</div>
      <div class="list">
        <div class="item">
          <img
            @click="uti.goPath('https://twitter.com/Polysmartchain')"
            src="../assets/img2.0/twitter.png"
            alt=""
          />
          <div class="span">Twitter</div>
        </div>
        <div class="item">
          <img
            @click="uti.goPath('https://medium.com/@polysmartchain')"
            src="../assets/img2.0/medium.png"
            alt=""
          />
          <div class="span">Medium</div>
        </div>
        <div class="item">
          <img
            @click="uti.goPath('https://discord.gg/RtjASquGBr')"
            src="../assets/img2.0/discord.png"
            alt=""
          />
          <div class="span">Discord</div>
        </div>
        <div class="item">
          <img
            @click="uti.goPath('https://t.me/PSCoffivcial')"
            src="../assets/img2.0/telegram.png"
            alt=""
          />
          <div class="span">Telegram</div>
        </div>
        <div class="item">
          <img
            @click="uti.goPath('https://t.me/+rZwfr_U9vx5mNzU1')"
            src="../assets/img2.0/telegram.png"
            alt=""
          />
          <div class="span">菠萝学院</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../store";
export default {
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
    };
  },
};
</script>

<style lang="less" scoped>
.m-about {
  .title {
    font-size: (16 / 3.75vw);
    margin-top: (15 / 3.75vw);
  }
  .fen {
    height: (10 / 3.75vw);
    width: 100%;
    background-color: #f1f1f1;
  }

  .list {
    display: flex;
    margin-top: (10 / 3.75vw);
    justify-content: center;
    .item {
      text-align: center;
      img {
        width: (60 / 3.75vw);
        height: (60 / 3.75vw);
        margin: 0 auto;
        margin-bottom: (10 / 3.75vw);
      }
      .span {
        font-size: (12 / 3.75vw);
      }
    }
  }
  .my {
    .list {
      margin: (20 / 3.75vw) 0;
    }
  }
  .office {
    padding: 0 (10 / 3.75vw);
    .list {
      justify-content: space-around;
      img {
        width: (40 / 3.75vw);
        height: (40 / 3.75vw);
      }
    }
  }
  .bottom {
    padding: 0 (10 / 3.75vw);
    padding-top: (21 / 3.75vw);
    padding-bottom: (36 / 3.75vw);
    .logo {
      width: 100%;
      text-align: center;
      margin-top: (20 / 3.75vw);
      // justify-content: center;
      // display: flex;
      // align-items: center;
      img {
        margin: 0 auto;
        width: (105 / 3.75vw);
      }
      .but {
        /* 矩形 4 */
        margin: (10 / 3.75vw) auto;

        width: (100 / 3.75vw);
        height: (34 / 3.75vw);
        line-height: (34 / 3.75vw);
        border-radius: (5 / 3.75vw);
        background: #ff7d41;
        box-shadow: 0px 2px (3 / 3.75vw) 0px rgba(176, 176, 176, 0.5);
        /* 点击打赏 */
        font-family: PingFang SC Medium;
        font-size: (14 / 3.75vw);
        font-weight: normal;
        letter-spacing: 0em;
        color: #ffffff;
      }
    }
    .p {
      margin-top: (18 / 3.75vw);
      // padding: 0 (18 / 3.75vw);
      /* 我们接受USDT、PSC、BNB、Matic 捐赠感谢你的捐赠，你的支持是我们前进的动力(支持PSC、Polygon、BNB Chain网络)。 */
      font-family: PingFang SC Medium;
      font-size: (12 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      .addr {
        color: #333333;
      }
    }
  }
}
</style>
